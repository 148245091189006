<template>
  <div v-if="sectionData">
    <div class="bg-gray-50 relative z-20">
      <svg class="hidden lg:block absolute top-0 right-0  -mr-20"  style="z-index:-1" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
        <defs>
          <pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
      </svg>
      <div class="max-w-7xl mx-auto py-24 sm:px-2 sm:py-24 lg:px-4 sm:pb-0 pb-0">
        <div class="max-w-2xl mx-auto px-4 lg:max-w-none">
          <div
            class="grid grid-cols-1 items-center gap-y-10 gap-x-16 lg:grid-cols-2"
          >
            <div>
              <h2 class="
              mt-1
              text-4xl
              font-extrabold
              text-gray-900
              sm:text-5xl
              sm:tracking-tight
              lg:text-6xl
            ">
                {{ sectionData[0].topText }}
              </h2>
              <p class="mt-4 text-gray-500">
                {{ sectionData[0].middleText }}
              </p>
            </div>
            <div
              class="aspect-w-3 aspect-h-2 bg-gray-100 rounded-lg overflow-hidden"
            >
              <img
                src="https://tailwindui.com/img/ecommerce-images/incentives-07-hero.jpg"
                alt=""
                class="object-center object-cover"
              />
            </div>
          </div>
          <div class="relative top-24 mt-4">
            <div class="bg-white shadow-xl border-gray-50 border-t rounded-lg">
              <h2 class="sr-only">Our perks</h2>
              <div
                class="
                  max-w-7xl
                  mx-auto
                  divide-y divide-gray-200
                  py-8 lg:flex lg:justify-center lg:divide-y-0 lg:divide-x
                "
              >
                <div class="lg:py-0 lg:w-1/3 lg:flex-none">
                  <div
                    class="
                      text-center
                      max-w-xs
                      mx-auto
                      px-4
                      rounded-xl
                      lg:max-w-none lg:px-8
                    "
                  >
                    <img src="Step1.svg" class="h-20 w-20 mx-auto"> 
                    <div class="mt-4">
                      <h3 class="font-bold text-gray-900 text-base">
                        {{ sectionData[1].topText }}
                      </h3>
                      <p class="mt-1 text-base text-gray-500">
                        {{ sectionData[1].middleText }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="py-8 lg:py-0 lg:w-1/3 lg:flex-none">
                  <div
                    class="
                      text-center
                      mx-auto
                      px-4 lg:px-8
                    "
                  >
                  
                    <img src="Step2.svg" class="h-20 w-20 mx-auto"> 
                    <div class="mt-4">
                      <h3 class="font-bold text-gray-900 text-base">
                        {{ sectionData[2].topText }}
                      </h3>
                      <p class="mt-1 text-gray-500 text-base">{{ sectionData[2].middleText }}</p>
                    </div>
                  </div>
                </div>
                <div class="py-8 lg:py-0 lg:w-1/3 lg:flex-none">
                  <div
                    class="
                      max-w-xs
                      mx-auto
                      px-4
                      text-center
                      lg:max-w-none lg:px-8
                    "
                  >
                  <img src="Step3.svg" class="h-20 w-20 mx-auto"> 
                    <div class="mt-4">
                      <h3 class="font-bold text-base text-gray-900">
                        {{ sectionData[3].topText }}
                      </h3>
                      <p class="mt-1 text-base text-gray-500">
                        {{ sectionData[3].middleText }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-white border-b border-gray-100 flex flex-col pt-36 justify-center py-24 px-6 lg:px-8 relative"
      id="register"
    >
          <svg class="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20 z-10" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
              <defs>
                <pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
            </svg>
            <svg class="hidden lg:block absolute bottom-0 left-0 -ml-20 z-10" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
                <defs>
                  <pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
              </svg>

      <div class="sm:mx-auto relative z-20">
        <h2 class="
              mt-1
              text-4xl
              font-extrabold
              text-gray-900
              sm:text-5xl
              sm:tracking-tight
              lg:text-6xl
              text-center ">
          {{ sectionData[4].topText }}
        </h2>
        <div class="relative text-lg text-gray-700 font-medium mt-4 mb-4">
          <p class="relative text-center mb-5">{{ sectionData[4].middleText }}</p>
        </div>
      </div>

      <div class="mt-4 sm:mx-auto sm:w-full sm:max-w-xl  relative z-20">
        <div class="border-t border-gray-50  py-8 px-4 shadow-xl sm:rounded-lg sm:px-10 bg-white">
          <!-- Registration succeeded message -->
          <div v-if="showSuccess" class="rounded-md bg-green-50 p-4 mb-8">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: solid/check-circle -->
                <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-green-800">
                  {{ message }}
                </h3>
                <!--<div class="mt-2 text-sm text-green-700">
                  <p class="text-green-700  text-sm">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam.
                  </p>
                </div>-->
              </div>
            </div>
          </div>
          <!-- Login failed message -->
          <div v-if="showFailure" class="rounded-md bg-red-50 p-4 mb-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: solid/x-circle -->
                <svg
                  class="h-5 w-5 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">Failed to register</h3>
                <div class="mt-2 text-red-700">
                  <p class=" text-red-700 text-sm ">{{ message }}</p>
                </div>
              </div>
            </div>
          </div>
          <LoadingSpinner v-if="isLoading" class="justify-center items-center"/>
          <form v-if="!isLoading" class="space-y-6" @submit="createAccount">
            <div>
              <label for="email" class="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div class="mt-1 relative">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5 text-gray-400"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg></div>
                <input
                  @input="setUserData('Email', $event.target.value)"
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required=""
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    pl-10
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    sm:text-sm
                  "
                />
              </div>
            </div>

            <div>
              <label
                for="password"
                class="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div class="mt-1">
                <input
                  @input="setUserData('Password', $event.target.value)"
                  id="password"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  required=""
                  class="
                    appearance-none
                    block
                    w-full
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    sm:text-sm
                  "
                />
              </div>
            </div>

            <div>
              <label
                for="password"
                class="block text-sm font-medium text-gray-700"
              >
                Confirm Password
              </label>
              <div class="mt-1">
                <input
                  @input="setUserData('Password', $event.target.value)"
                  id="password2"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  required=""
                  class="
                    appearance-none
                    block
                    w-full
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    sm:text-sm
                  "
                />
              </div>
            </div>

            <div>
              <button
                class="
                  w-full
                  flex
                  justify-center
                  whitespace-nowrap
                  inline-flex
                  items-center
                  justify-center
                  bg-origin-border
                  px-4
                  py-2
                  border border-transparent
                  rounded-md
                  shadow-sm
                  text-base
                  font-medium
                  text-white
                "
                :disabled="!hasEssentialFields && passwordsMatch"
                v-bind:class="[
                  hasEssentialFields && passwordsMatch
                    ? 'bg-gradient-to-r from-blue-500 to-blue-700 hover:bg-blue-700 hover:to-blue-500'
                    : 'bg-gradient-to-r from-blue-500 to-blue-700 opacity-70 cursor-not-allowed',
                  ]"
              >
                Create my profile!
              </button>
              <!-- <router-link
                to="/Dashboard"
                :onclick="createAccount()"
                class="
                  w-full
                  flex
                  justify-center
                  whitespace-nowrap
                  inline-flex
                  items-center
                  justify-center
                  bg-gradient-to-r
                  from-blue-500
                  to-blue-700
                  bg-origin-border
                  px-4
                  py-2
                  border border-transparent
                  rounded-md
                  shadow-sm
                  text-base
                  font-medium
                  text-white
                  hover:from-blue-700 hover:to-blue-500
                "
              >
                Create my profile!
              </router-link> -->
            </div>
          </form>

          <div class="mt-6">
            <div class="relative">
              <div class="absolute inset-0 flex items-center">
                <div class="w-full border-t border-gray-300" />
              </div>
              <div class="relative flex justify-center text-sm">
                <span class="px-2 bg-white text-gray-800 font-medium">
                  Already have an account?
                </span>
              </div>
            </div>

            <div class="mt-6 grid grid-cols-1 gap-3">
              <div>
                <a href="/login" class="router-link-active router-link-exact-active w-full
              whitespace-nowrap
              inline-flex
              items-center
              justify-center
              bg-gradient-to-r
              from-indigo-500
              to-indigo-700
              bg-origin-border
              px-4
              py-2
              border border-transparent
              rounded-md
              shadow-sm
              text-base
              font-medium
              text-white
              hover:from-indigo-700 hover:to-indigo-500" id="" aria-current="page"> Sign In </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "../components/LoadingSpinner.vue";
import { CalendarIcon, RefreshIcon, TruckIcon } from "@heroicons/vue/outline";
import { OcContentService } from "../services/ocContentService";
import { NopService } from "../services/nopService";
import { CookieService } from '../services/cookieService';
import { useReCaptcha } from 'vue-recaptcha-v3'

const perks = [
  {
    name: "10-year all-inclusive warranty",
    description: "We’ll replace it with a new one",
    icon: CalendarIcon,
  },
  {
    name: "Free shipping on returns",
    description: "Send it back for free",
    icon: RefreshIcon,
  },
  {
    name: "Free, contactless delivery",
    description: "The shipping is on us",
    icon: TruckIcon,
  },
];

const newUser = {
    Password:"",
    Email:"",
    IsActive: true,
    MaxNumberOfVideos: 4, //default value
    MaxNumberOfImages:4, //default value
    IsAccountPrivate:true, //default value
    IsPremium:false, //default value
    Country:"Canada", //default value
    Radius:0, //default value
    ProfileImageUUID:"1ae7caba-848a-465f-8cc7-952235f60bc2", //default value
    HeroImageUUID:"8db14653-96d1-411f-a314-49592d808884", //default value
    IsFlatRate: false, //default value
    City:"",
    Province:"",
    Username:"",
    About:"",
    FirstName:"",
    LastName:"",
    WebsiteUrl:"",   
    Phone:"",
    Categories:"",
    BillingEmailAddress:"",
    Company:"",
    BillingAddress:"",
    BillingApartment:"",
    BillingCity:"",
    BillingProvince:"",
    BillingPostalCode:"",
    BillingCountry:"",
    Address:"",
    ZipPostalCode:"",
    OtherImagesUUID:"",
    VideoUUID:""
  }

export default {
  components: {
    LoadingSpinner
  },
  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

    const recaptcha = async () => {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded()
      // Execute reCAPTCHA with action "login".
      const gtoken = await executeRecaptcha('login')
      // Do stuff with the received token.
      let resp = await NopService.validateRecaptcha(gtoken)
      return (resp.data.status == 1)
    }
    return {
      recaptcha,
      perks,
      newUser,
    };
  },
  data() {
    return {
      sectionData: null,
      passwordsMatch: false,
      showFailure: false,
      showSuccess: false,
      message: "",
      isLoading: false,
      hasEssentialFields: false,
      isValidRecaptcha: false,
    }
  },
  created() {
    this.isLoading = true;
    //need to do this to check whether the nav bar needs to be updated
    if (!CookieService.getLoginCookie()) {
      this.emitter.emit("logged-in", false);
    }
    this.fetchOcData();
    this.isLoading = false;
  },
  methods: {
    async fetchOcData() {
      this.sectionData = await OcContentService.fetchPageData(
        "Register",
        "KrickersSectionContent"
      );
    },
    async createAccount(event) {
      event.preventDefault()
      //READ THIS:
      //https://stackoverflow.com/questions/42091805/add-event-listener-to-router-link-component-using-v-on-directive-vuejs
      this.isValidRecaptcha = await this.recaptcha();
      if (this.isValidRecaptcha && this.passwordsMatch) {
        this.isLoading = true;
        this.showSuccess = false;
        this.showFailure = false;
        this.generateProfileUrl();
        let resp = await NopService.CreateCustomer(this.newUser);
        if (resp.data.Message === "Customer has been registered successfully") { //MAKE SURE THIS CONDITIONAL DOES A PROPER CHECK TO ENSURE THE USER WAS CREATED
          this.showSuccess = true;
          this.message = "Please check your email for a verification link.";
          // let loginDetails = {
          //   Password: newUser.Password,
          //   Email: resp.data.Data.Email
          // }
          // resp = await NopService.LogIn(loginDetails, false);
          // if (resp.data.Status == 1) {
          //   //login was successful
          //   this.emitter.emit("logged-in", true);
          //   this.$router.push({ path: 'Dashboard' })
          // }
          // else {
          //   this.showFailure = true;
          //   this.message = resp.data.Message;
          // }
        }
        else {
          this.showFailure = true;
          this.message = resp.data.Message;
        }
        this.isLoading = false;
      }
      //THIS WAS COPIED FROM THE PART THAT CALLS createAccount()
      document.getElementById('signInButton').click();
      sessionStorage.setItem('login','true');
    },
    setUserData(field, value) {
      this.newUser[field] = value;
      let password1 = document.getElementById("password").value;
      let password2 = document.getElementById("password2").value;
      this.passwordsMatch = password1 === password2;
      this.checkEssentialFields(password1, password2)
    },
    generateProfileUrl() {
      if (!this.newUser.Username) {
        var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var result = '';
        for ( var i = 0; i < 20; i++ ) {
            result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        this.newUser.Username = result;
      }
    },
    checkEssentialFields(p1, p2) {
      if (this.newUser.Email && p1 && p2) {
        this.hasEssentialFields = true;
      }
      else {
        this.hasEssentialFields = false;
      }
    }
  }
};
</script>